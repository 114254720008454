import React from 'react'
import { graphql, Link } from 'gatsby'

import MarkdownRenderer from '../components/MarkdownRenderer'
import { TestimonialsQuery } from '../../graphql-types'
import { Content } from '../components/styled'
import Layout from '../components/layout'
import { GRAY } from '../Utils'

const testimonials = ({
  location,
  data,
}: {
  location: Location
  data: TestimonialsQuery
}) => {
  return (
    <Layout location={location}>
      <Content>
        <div className="sidenav mobile-hide">
          <div className="logo-width"></div>
        </div>
        <div>
          <MarkdownRenderer
            className="about-text"
            html={
              data.allContentfulAbout.edges[0].node.testimonials
                .childMarkdownRemark.html
            }
          />
        </div>
      </Content>
    </Layout>
  )
}

export default testimonials

export const pageQuery = graphql`
  query Testimonials {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAbout {
      edges {
        node {
          testimonials {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
        }
      }
    }
  }
`
